import {
  Button,
  Layout,
  Modal,
  Popconfirm,
  Progress,
  Space,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import React, { FC, useEffect, useState } from 'react';
import { blue, red } from '@ant-design/colors';
import keyBy from 'lodash/keyBy';
import { models } from '@ynomia/core';
import ModelEditor, { ApsModel } from '../../components/connected/ModelEditor';
import {
  getAssetTypes,
  getAssetTypesById,
  getScratchProjectCode,
  getTenant,
} from '../../selectors';
import { getAssets, getLayers } from '../../actions';
import ModelSetupModal from '../../components/connected/ModelSetupModal';
import { PageProps } from '../../config/types';
import client from '../../services/Client';
import { formatDate } from '../../utils';
import { getContextStores } from '../../context';
import styles from './styles.module.less';
import * as digitalTwin from '../../services/digitalTwin';

const { Content } = Layout;

const Models: FC<PageProps> = () => {
  const contextStores = getContextStores();
  const {
    assetDispatch,
    assetState,
    layerDispatch,
    layerState,
  } = contextStores;
  // Upload Modal States
  const [isSetupModalOpen, setIsSetupModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

  // Models
  const [fetchingModels, setFetchingModels] = useState<boolean>(true);
  const [firstFetch, setFirstFetch] = useState<boolean>(true);
  const [apsModels, setApsModels] = useState<Array<ApsModel>>([]);
  const [modelsData, setModelsData] = useState<models.ModelResponse[]>();
  const [togglingModels, setTogglingModels] = useState<{ [objectKey: string]: boolean }>({});
  const [savingInProgress, setSavingInProgress] = useState<boolean>(false);
  const modelsKeyedById = keyBy(modelsData, 'id');

  const [stepOverride, setInitialStep] = useState<number>(0);
  const [focusedModelId, setFocusedModelId] = useState<string | undefined>(undefined);

  /* Selectors */
  const projectCode = getScratchProjectCode(contextStores);
  const tenant = getTenant(contextStores);

  const assetTypes = getAssetTypes(contextStores);
  const assetTypesKeyedById = getAssetTypesById(contextStores);

  const { lastFetchStartTime } = assetState;

  const apsModelsKeyedByObjectKey = keyBy(apsModels, 'objectKey');

  const refreshModels = async (fetchAPS = true) => {
    setFetchingModels(true);

    const fetchedModels = await digitalTwin.fetchModels(
      tenant,
      projectCode,
      {
        activeOnly: false,
        includeMapping: false,
      },
    );

    try {
      if (fetchAPS) {
        const listResp = await client.server.get(
          `/scratch/models/${tenant}/${projectCode}/list`,
        );
        setApsModels(listResp.data.value);
      }
    } finally {
      // Only update the UI once everything is done.
      setModelsData(fetchedModels);
      setFetchingModels(false);
      setFirstFetch(false);
    }
  };

  const updateModel = async (
    id: string,
    body: Partial<models.Model>,
  ) => {
    setSavingInProgress(true);
    await digitalTwin.patchModel(tenant, projectCode, id, body);
    await refreshModels(false);
    setSavingInProgress(false);
  };

  const createModel = async (
    body: Partial<models.Model>,
  ) => {
    setSavingInProgress(true);
    const res = await digitalTwin.createModel(tenant, projectCode, body);
    await refreshModels();
    setSavingInProgress(false);
    return res;
  };

  useEffect(() => {
    // Need this to visualise twinID mapping
    getAssets(tenant, projectCode, assetTypes, lastFetchStartTime, assetDispatch);
    getLayers(tenant, projectCode, layerState.lastFetchStartTime, layerDispatch);
  }, []);

  useEffect(() => {
    refreshModels();
    const t = setInterval(refreshModels, 60 * 1000);
    return () => clearInterval(t);
  }, []);

  const deleteModel = async (id: string) => {
    const modelToBeDeleted = modelsData?.find(m => m.id === id);
    if (!modelToBeDeleted) return;
    const objectKey = modelToBeDeleted?.fileName;
    await updateModel(id, { isArchived: true });
    if (apsModelsKeyedByObjectKey[objectKey]) {
      await client.server.delete(
        `/scratch/models/${tenant}/${projectCode}/object/${objectKey}`,
      );
    }
    await refreshModels();
  };

  const generateStatus = (id: string, m: ApsModel) => {
    if (!m) {
      return <div style={{ textAlign: 'center' }}>Not on APS</div>;
    }

    const getModelStatus = (): {
      step: number,
      fillColor: string,
      tooltipMessage: string,
      icon: React.ReactNode
    } => {
      const failed = red[6];
      const normal = blue[6];
      if (!m) {
        return {
          step: 0,
          fillColor: normal,
          tooltipMessage: '',
          icon: null,
        };
      }

      if (!m.manifest || m.manifest.status === 'failed') {
        return {
          step: 2,
          fillColor: failed,
          tooltipMessage: 'Failed to translate',
          icon: <CloseCircleOutlined />,
        };
      }

      if (m.manifest?.progress !== 'complete') {
        return {
          step: 2,
          fillColor: normal,
          tooltipMessage: `Translating - ${m.manifest?.progress}`,
          icon: <LoadingOutlined />,
        };
      }

      if (!modelsKeyedById[id]?.isMapped) {
        return {
          tooltipMessage: 'Mapping Required.',
          fillColor: normal,
          step: 3,
          icon: <ExclamationCircleOutlined />,
        };
      }

      if (!modelsKeyedById[id].active) {
        return {
          tooltipMessage: 'Enable the model to publish (Mapped)',
          fillColor: normal,
          step: 4,
          icon: <CheckCircleOutlined />,
        };
      }

      return {
        tooltipMessage: 'Published (Mapped)',
        fillColor: normal,
        step: 4,
        icon: <CheckCircleOutlined />,
      };
    };

    const totalSteps = 5;
    const {
      step, fillColor, tooltipMessage, icon,
    } = getModelStatus();
    const strokeColor = new Array(totalSteps).fill(fillColor);
    return (
      <div style={{ textAlign: 'center' }}>
        <Tooltip title={tooltipMessage} style={{ alignSelf: 'center' }}>
          <div
            onClick={() => {
              setFocusedModelId(id);
              setInitialStep(step);
              setIsSetupModalOpen(true);
            }}
            style={{ cursor: 'pointer' }}
            role="button"
            tabIndex={0}
          >
            <Progress
              steps={totalSteps}
              percent={((step + 1) / totalSteps) * 100}
              strokeColor={strokeColor}
              format={() => icon}
            />
          </div>
        </Tooltip>
      </div>
    );
  };

  const tableColumns = [
    {
      title: 'Enabled',
      dataIndex: 'enabled',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Upload File',
      dataIndex: 'originalFileName',
      sorter: true,
    },
    {
      title: 'Asset Type(s)',
      dataIndex: 'assetTypes',
    },
    {
      title: 'TwinID Field',
      dataIndex: 'twinIdField',
      sorter: true,
    },
    {
      title: 'Upload Date',
      dataIndex: 'date',
      sorter: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      width: 140,
    },
  ];

  const setModelEnabled = async (id: string, enable: boolean) => {
    setTogglingModels(s => ({ ...s, [id]: true }));
    setSavingInProgress(true);
    await updateModel(id, { active: enable });
    await refreshModels();
    setSavingInProgress(false);
    setTogglingModels(s => ({ ...s, [id]: false }));
  };

  const generateActions = (id: string, disableDeleteButton?: boolean) => (
    <Space size="middle" style={{ justifyContent: 'start', width: '100%' }}>
      <Typography.Link
        onClick={() => {
          setFocusedModelId(id);
          setIsEditModalOpen(true);
        }}
      >
        Edit
      </Typography.Link>
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => deleteModel(id)}
      >
        <Typography.Link
          disabled={disableDeleteButton}
          type="danger"
        >
          Delete
        </Typography.Link>
      </Popconfirm>
    </Space>
  );

  const dataSource = Object.keys(modelsKeyedById).map((id) => {
    const model = modelsKeyedById[id];
    const apsModel = apsModelsKeyedByObjectKey[model.fileName];
    const { fileName } = model;
    const enabled = model.active;
    const disableToggle = savingInProgress
      || !model.urn
      || (apsModel && apsModel.manifest.progress !== 'complete');
    return ({
      key: id,
      name: fileName,
      enabled: <Switch
        disabled={disableToggle}
        checked={enabled}
        onClick={() => setModelEnabled(id, !enabled)}
        loading={togglingModels[id]}
      />,
      status: generateStatus(id, apsModel),
      action: generateActions(id, enabled || savingInProgress),
      assetTypes: model.assetTypes?.map(t => assetTypesKeyedById.get(t)?.name?.toLowerCase())
        .join(', '),
      twinIdField: model.twinIdField,
      originalFileName: model.originalFileName,
      date: formatDate(model.date),
    });
  });

  return (
    <Content className={styles.container}>
      <div className={styles.header}>
        <Typography.Title level={3} style={{ flex: 1 }}>
          Current Models
        </Typography.Title>
        <Button
          type="primary"
          icon={<SyncOutlined />}
          style={{ marginTop: 24, marginRight: 10 }}
          size="middle"
          onClick={() => refreshModels()}
          loading={fetchingModels}
        />
        <Button
          type="primary"
          onClick={() => {
            // Open Modal
            setFocusedModelId(undefined);
            setInitialStep(0);
            setIsSetupModalOpen(true);
          }}
          style={{ width: 100, marginTop: 24 }}
        >
          Add Model
        </Button>
      </div>
      <Table
        columns={tableColumns}
        dataSource={dataSource}
        loading={firstFetch}
        scroll={{ x: 'max-content' }}
      />
      <ModelSetupModal
        modelIdOverride={focusedModelId}
        modelsKeyedById={modelsKeyedById}
        apsModels={apsModels}
        savingInProgress={savingInProgress}
        isSetupModalOpen={isSetupModalOpen}
        stepOverride={stepOverride}
        setIsSetupModalOpen={setIsSetupModalOpen}
        setModelEnabled={setModelEnabled}
        updateModel={async (id, options) => {
          await updateModel(id, options);
          await refreshModels(true);
        }}
        createModel={createModel}
      />
      <Modal
        title={`Edit — ${modelsKeyedById[focusedModelId!]?.fileName}`}
        open={focusedModelId !== undefined && isEditModalOpen}
        width={1000}
        footer={null}
        maskClosable={false}
        onCancel={() => setIsEditModalOpen(false)}
        destroyOnClose
      >
        <ModelEditor
          modelId={focusedModelId!}
          modelsKeyedById={modelsKeyedById}
          apsModel={apsModelsKeyedByObjectKey[focusedModelId!]}
          disableUpdate={savingInProgress}
          updateModel={updateModel}
        />
      </Modal>
    </Content>
  );
};

export default Models;
