import { Asset } from '@ynomia/core/dist/project/assets';
import { createSelector } from 'reselect';
import keyBy from 'lodash/keyBy';
import {
  getAssetManagerFilters,
  getAssetTypes,
  getFilteredAssets,
  getFilteredAssetsContext,
  getAssetManagerAssetSelection,
} from '..';
import map from '../map';

export const getFilteredAssetsForDigitalTwin = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      selectedBandIds,
      multiSearchModeFilter,
      fieldFilters,
    } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      selectedLayerIds,
      tableFilter,
      openedLayerId,
      selectedBandIds,
      multiSearchModeFilter,
      fieldFilters,
    });
  },
);

const getFilteredAssetsForTwin = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const { openedLayerId } = assetManagerFilters;
    return getFilteredAssets(filteredAssetsContext, {
      openedLayerId,
    });
  },
);

export const getFilteredAssetKeyedByTwinId = createSelector(
  [
    getFilteredAssetsForDigitalTwin,
  ],
  filteredAssetsForDigitalTwin => new Map(filteredAssetsForDigitalTwin.map((asset) => {
    const [twinID] = asset.destination?.twinID || [];
    return [twinID, asset];
  })),
);

export const getTwinIDModelIdMap = createSelector(
  [map.models],
  (models) => {
    const output: { [TwinID: string]: string } = {};
    if (!models) return [];
    models.forEach((model) => {
      const { mapping } = model;
      Object.keys(mapping!).forEach((twinID) => {
        output[twinID] = model.id || '';
      });
    });
    return output;
  },
);

export const getModelsKeyedById = createSelector(
  [map.models],
  models => keyBy(models, 'id'),
);

export const getFilteredModelIds = createSelector(
  [getTwinIDModelIdMap, getFilteredAssetsForTwin],
  (twinIDModelIdMap, assets) => {
    const modelIdsSet = new Set<string>();
    assets.forEach((asset) => {
      const twinID = asset?.destination?.twinID?.[0];
      if (!twinID || !twinIDModelIdMap[twinID]) return;
      modelIdsSet.add(twinIDModelIdMap[twinID]);
    });
    return modelIdsSet.size ? [...modelIdsSet] : undefined;
  },
);

export const getFilteredAssetsKeyedByStatusForDigitalTwin = createSelector(
  [getAssetTypes, getFilteredAssetsForDigitalTwin],
  (assetTypes, filteredAssets) => {
    const assetKeyedByStatuses = new Map();

    if (assetTypes && filteredAssets) {
      const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

      statusesArray?.forEach(({ id }) => {
        const assetsFilteredByStatusId = filteredAssets.filter(({ status }) => status === id);
        assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
      });
    }

    return assetKeyedByStatuses as Map<string, Array<Asset>>;
  },
);

export const getSelectedAssetsKeyedByStatusForDigitalTwin = createSelector(
  [getAssetTypes, getFilteredAssetsForDigitalTwin],
  (assetTypes, filteredAssets) => {
    const assetKeyedByStatuses = new Map();

    if (assetTypes && filteredAssets) {
      const statusesArray = assetTypes.map(assetType => assetType.statuses!).flat();

      statusesArray?.forEach(({ id }) => {
        const assetsFilteredByStatusId = filteredAssets.filter(({ status }) => status === id);
        assetKeyedByStatuses.set(id, assetsFilteredByStatusId);
      });
    }

    return assetKeyedByStatuses as Map<string, Array<Asset>>;
  },
);

export const getUnMappedAsset = createSelector(
  [
    map.assetsKeyedById,
    getAssetManagerAssetSelection,
    getTwinIDModelIdMap,
  ],
  (assetsKeyedById, assetManagerAssetSelection, twinIDModelIdMap) => {
    const { selectedAssets } = assetManagerAssetSelection;
    return selectedAssets?.filter((id) => {
      const currentAsset = assetsKeyedById.get(id);
      const twinID = currentAsset?.destination?.twinID?.[0] || null;
      return !twinID || !twinIDModelIdMap[twinID];
    });
  },
);
