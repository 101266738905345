import kebabCase from 'lodash/kebabCase';

export const getProjectIdFromSlug = (projectSlug?: string) => {
  if (!projectSlug) return undefined;
  const parts = projectSlug.split('-');
  const projectId = parts[parts.length - 1];

  if (!projectId.match(/^[0-9a-fA-F]{24}$/)) return undefined;
  return projectId;
};

export const getProjectIdFromUrl = () => {
  const pathArray = window.location.pathname.split('/');
  const projectSlug = pathArray[1];
  if (!projectSlug) return undefined;
  const parts = projectSlug.split('-');
  const projectId = parts[parts.length - 1];

  if (!projectId.match(/^[0-9a-fA-F]{24}$/)) return undefined;
  return projectId;
};

export const generateProjectSlug = (name: string, id: string) => `${kebabCase(name)}-${id}`;
