import { createSelector } from 'reselect';
import { PlannedTwinID } from '@ynomia/core/dist/blueprint';
import {
  getAssetManagerFilters, getFilteredAssets, getFilteredAssetsContext,
  getPlansKeyedByTwinId,
} from '..';
import { Asset } from '../../config/types';

export const getAssetsKeyedByBandId = (
  plannedTwinIDKeyedByTwinId: Map<string, PlannedTwinID>,
  filteredAssetsForPlan: Array<Asset>,
) => {
  const assetsKeyedByBandId = new Map<string, Array<Asset>>();
  filteredAssetsForPlan.forEach((asset) => {
    const { destination } = asset;
    const [twinID] = destination?.twinID || [];
    const bandID = plannedTwinIDKeyedByTwinId.get(twinID || '')?.result?.bandID || 'unknown';
    if (!assetsKeyedByBandId.has(bandID)) {
      assetsKeyedByBandId.set(
        bandID,
        [],
      );
    }

    assetsKeyedByBandId.get(bandID)?.push(asset);
  });

  return assetsKeyedByBandId;
};

export const getFilteredAssetsForPlan = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      multiSearchModeFilter,
      selectedLayerIds,
      fieldFilters,
    } = assetManagerFilters;
    if (
      !searchTags
      && !dateFilter?.length
      && !typeFilter
      && !assetStatusFilter?.ids?.length
      && !observationTypeFilter?.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      multiSearchModeFilter,
      selectedLayerIds,
      fieldFilters,
    });
  },
);

export const getFilteredAssetsKeyedByBandId = createSelector(
  [getPlansKeyedByTwinId, getFilteredAssetsForPlan],
  getAssetsKeyedByBandId,
);

export const getNumberOfAssetWithoutPlan = createSelector(
  [getFilteredAssetsKeyedByBandId],
  assetsKeyedByBandId => assetsKeyedByBandId?.get('unknown')?.length ?? 0,
);
