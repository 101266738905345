import { createSelector } from 'reselect';
import {
  getAssetManagerFilters,
  getAssetManagerTwinPlanId,
  getAssetStatusesKeyedById,
  getAssetTypes,
  getCurrentPlanBandsForCharts,
  getLayers,
  getLayersKeyedByAncestorId,
  getPlansKeyedByTwinId,
  getPrimaryLayers,
  getSelectedAssetType,
} from '..';
import { keyAssetsByStatus, getSelectedLayerAncestors } from '../../utils';
import { getFilteredAssets, getFilteredAssetsContext, getAssetsKeyedByBandId } from '.';
import map from '../map';

const getFilteredAssetsForLayerSummary = createSelector(
  [
    getAssetManagerFilters,
    getFilteredAssetsContext,
  ],
  (
    assetManagerFilters,
    filteredAssetsContext,
  ) => {
    const {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      selectedBandIds,
      multiSearchModeFilter,
      fieldFilters,
    } = assetManagerFilters;
    if (
      !searchTags
      && !dateFilter?.length
      && !typeFilter
      && !assetStatusFilter?.ids?.length
      && !observationTypeFilter?.size
    ) {
      return filteredAssetsContext.assetsArray;
    }

    return getFilteredAssets(filteredAssetsContext, {
      searchTags,
      searchText,
      dateFilter,
      typeFilter,
      selectedBandIds,
      assetStatusFilter,
      observationTypeFilter,
      tableFilter,
      multiSearchModeFilter,
      fieldFilters,
    });
  },
);

export const getAssetsKeyedByStatusForLayerSummary = createSelector(
  [getAssetTypes, getFilteredAssetsForLayerSummary],
  keyAssetsByStatus,
);

export const getSelectedLayerAncestorsForLayerSummary = createSelector(
  [
    getLayersKeyedByAncestorId,
    getAssetManagerFilters,
  ],
  (
    layersKeyedByAncestorId,
    assetManagerFilters,
  ) => {
    const {
      selectedLayerIds,
    } = assetManagerFilters;
    return getSelectedLayerAncestors(selectedLayerIds, layersKeyedByAncestorId);
  },
);

export const getFilteredAssetsKeyedByBandIdForLayerSummary = createSelector(
  [getPlansKeyedByTwinId, getFilteredAssetsForLayerSummary],
  getAssetsKeyedByBandId,
);

export const getChartistDataForLayerSummary = createSelector(
  [
    map.presenterMode,
    getAssetManagerTwinPlanId,
    getAssetStatusesKeyedById,
    getAssetsKeyedByStatusForLayerSummary,
    getPrimaryLayers,
    getLayersKeyedByAncestorId,
    getSelectedAssetType,
    getFilteredAssetsKeyedByBandIdForLayerSummary,
    getCurrentPlanBandsForCharts,
    getLayers,
    getAssetManagerFilters,
    getSelectedLayerAncestorsForLayerSummary,
  ],
  (
    presenterMode,
    twinPlanId,
    assetStatusesKeyedById,
    assetsKeyedByStatus,
    primaryLayers,
    layersKeyedByAncestorId,
    selectedAssetType,
    assetsKeyedByBandId,
    currentPlanBands,
    layers,
    assetManagerFilters,
    selectedLayerAncestors,
  ) => {
    const { statuses } = selectedAssetType;
    const { selectedLayerIds } = assetManagerFilters;
    const getStatus = () => {
      const series = statuses?.map((status) => {
        const statusId = status.id;
        const statusLabel = assetStatusesKeyedById.get(statusId)!?.label;
        let newSubSeries: Array<{ meta: string; value: number }> = [];
        let displayedLayer = layers;

        if (presenterMode && selectedLayerIds.length) {
          displayedLayer = layers.filter(({ id }) => selectedLayerAncestors.includes(id));
        }

        newSubSeries = displayedLayer?.map((layer) => {
          const { id } = layer;
          const descendantLayers = layersKeyedByAncestorId.get(id) || [];
          const inScopeLayers = new Set([layer, ...descendantLayers].map(l => l.id));
          const count = assetsKeyedByStatus.get(statusId)?.reduce((acc, asset) => {
            if (inScopeLayers.has(asset?.destination?.layerID!)) {
              return acc + 1;
            }
            return acc;
          }, 0) ?? 0;
          return {
            meta: statusLabel,
            value: count,
          };
        });

        return newSubSeries;
      });

      return series?.reverse() || [];
    };

    const getPlan = () => currentPlanBands.map((band) => {
      const assets = assetsKeyedByBandId.get(band.id) || [];
      let displayedLayer = layers;
      if (presenterMode && selectedLayerIds.length) {
        displayedLayer = layers.filter(({ id }) => selectedLayerAncestors.includes(id));
      }

      const newSubSeries = displayedLayer?.map((layer) => {
        const { id } = layer;
        const descendantLayers = layersKeyedByAncestorId.get(id) || [];
        const inScopeLayers = new Set([layer, ...descendantLayers].map(l => l.id));
        const count = assets.reduce((acc, asset) => {
          if (inScopeLayers.has(asset?.destination?.layerID!)) {
            return acc + 1;
          }
          return acc;
        }, 0);
        return {
          meta: band.label,
          value: count,
        };
      });

      return newSubSeries;
    }).reverse();
    const labels = primaryLayers?.length > 0
      ? layers?.filter(
        ({ id }) => (
          presenterMode && selectedLayerIds.length
            ? selectedLayerAncestors.includes(id)
            : true
        ),
      ).map(({ label }) => label) : [] as Array<string>;
    const data = {
      labels,
      series: twinPlanId ? getPlan() : getStatus(),
    };

    return data;
  },
);
